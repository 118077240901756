const ATTRIBUTE_TYPE_BOOLEAN = 1;   // type id of boolean attributes
const NODE_TYPE_COMPOSITE = 0x04;   // A real world object whose internal structure is not relevant to end user
// technical attributes to be skipped while inheriting in `collectProperties`
const skipKeys = new Set(['composite', 'isComposite', 'found']);

/** @import { PropertyDatabase } from './Propdb' */

/**
 * @param {PropertyDatabase} pdb - the property database
 * @param {Function} predicate - the filter predicate function
 * @param {object} options
 * @param {Record<number,string>} options.propertyMapping - the record, which maps property indices to their hashes
 * @param {number[]} [options.dbIds] - the object IDs to get values for. If undefined, get values for all objects having geometries
 * @param {string[]} options.valuesProperties - the property hashes to get values for
 * @param {string[]} options.elementsProperties - the property hashes to get values per object for
 * @param {string} [options.groupedProperty] - the property hash to group object IDs per value for
 * @param {boolean} [options.valuesOnly] - only return the values, not the ids (for use w/ valuesProperties or groupedProperty)
 * @param {boolean} [options.composites] - use and return composite objects (as `ids2`) in filtering
 * @param {boolean} [options.strict] - optional: strict filtering: no parent/child inheritance, use renderability
 * @returns {object}
 */
export function findIds(pdb, predicate, { propertyMapping, dbIds, valuesProperties, elementsProperties, groupedProperty, valuesOnly, composites, strict }) {

    const parentId = pdb.getAttrParent();
    const childId = pdb.getAttrChild();
    const flagsId = pdb.getAttrNodeFlags();
    const aliases = new Map();

    const propertyMapArray = Object.entries(propertyMapping);

    const properties = Object.assign({
        [parentId]: true,
        [flagsId]: true
    }, propertyMapping);

    const attrTypes = propertyMapArray.reduce((types, [attrId, propertyHash]) => {
        types[attrId] = pdb.getAttributeDef(attrId)?.dataType;

        if (!aliases.get(parseInt(attrId))) {
            const same = propertyMapArray.filter(([_, pHash]) => propertyHash === pHash).map(([attrId]) => Number(attrId));

            if (same.length > 1) {
                same.forEach(attrId => aliases.set(attrId, same));
            }
        }

        return types;
    }, {});

    /** @typedef {boolean | number | string | null | undefined} Value */
    /** @type {Record<number, Record<number, number> & { isComposite?: boolean, composite?: number, found?: boolean }>} */
    const cache = {};
    /** @type {number[]} */
    const ids = [];
    /** @type {Record<string, Set<Value>>} */
    const vals = valuesProperties?.reduce((prev, key) => { prev[key] = new Set(); return prev; }, {}) ?? {};
    /** @type {Map<Value, number[]>} */
    const grouped = new Map();
    /** @type {Value[][]} */
    const elements = [];
    /** @type {Set<number>} */
    const ids2 = new Set();

    const hydrate = (rawItem) => {
        /** @type {Record<string, Value} */
        const item = {};
        for(const key of Object.keys(rawItem)) {
            const k = parseInt(key);
            if (k === parentId || k === childId || isNaN(k)) {
                continue;
            }
            const propertyHash = propertyMapping[key];
            if (propertyHash) {
                const vId = rawItem[key];
                const v = pdb.getAttrValue(k, vId);
                item[propertyHash] = attrTypes[k] === ATTRIBUTE_TYPE_BOOLEAN && v !== null && v !== ''? !!v : v;
            }
        }
        return item;
    };

    const gotIt = (id, item) => {
        if (!valuesOnly) {
            ids.push(id);
        }
        valuesProperties?.forEach((key) => vals[key].add(item[key]));
        if (elementsProperties) {
            var element = elementsProperties.map(key => item[key]);
            elements.push(element);
        }
        if (groupedProperty) {
            const value = item[groupedProperty];
            const objIds = grouped.get(value);
            if (objIds) {
                objIds.push(id);
            } else {
                grouped.set(value, [id]);
            }
        }
    };

    const visit = (id) => {
        const rawItem = collectProperties(pdb, id, properties, cache, aliases, strict);

        const compositeId = rawItem.composite;
        let compositeParentFilteredIn = false;
        if (composites !== undefined && compositeId !== undefined) {
            const rawComposite = cache[compositeId];
            if (rawComposite.found === undefined) {
                // first time visiting the composite
                const composite = hydrate(rawComposite);
                if (predicate(pdb, composite)) {
                    rawComposite.found = true;
                    gotIt(compositeId, composite);
                }
            }
            compositeParentFilteredIn = rawComposite.found;
            if (composites === true) {
                // composites only mode: ignore leaf objects of composites for filtering
                if (compositeParentFilteredIn) {
                    // all children of a found composite will be returned
                    ids2.add(id);
                }
                return;
            }
            // composites-or-leaves: apply the filter to the leaf object as well
        }
        // we get here for non-composite objects (regular objects or leaf objects)
        const item = hydrate(rawItem);
        if (predicate(pdb, item)) {
            gotIt(id, item);
            if (composites !== undefined && compositeId !== undefined && !compositeParentFilteredIn) {
                ids2.add(compositeId);
            }
            return;
        }

        if (compositeParentFilteredIn) {
            // all children of a found composite will be returned
            ids2.add(id);
        }

    };

    if (dbIds) {
        dbIds.forEach(visit);
    } else {
        pdb.enumObjects(visit);
    }
    const values = valuesProperties?.map((key) => Array.from(vals[key]));
    return { ids, values, elements, grouped, ids2: Array.from(ids2) };
}

/**
 * Collect attribute values of a dbId and all parents on its path.
 *
 * Note:
 *   - The same attribute may be defined by multiple parents
 *   - We only want to obtain exactly one tree path from each dbId.
 * Therefore, for each attribute, we (only) collect the first value that we find during upwards search in the tree.
 * @param {PropertyDatabase} pdb - the property database
 * @param {number} dbId - the object id
 * @param {Record<number, string|true>} attribWanted - attribute id - truthy mapping of properties we're interested in
 * @param {Record<number, Record<number, number>>} cache - caches the parent items
 * @param {Map<number, number[]>} aliases - undefined || attribute/attribute map - where both have the same name, dataType and category
 * @param {boolean} strict - use strict mode: no parent/child property inheritance
 * @returns {Record<number, number> & { isComposite?: boolean, composite?: number, found?: boolean }} - resulting attribute Id / value Id mapping for the object
 */
export function collectProperties(pdb, dbId, attribWanted, cache, aliases, strict) {
    const parentAttrId = pdb.getAttrParent();
    const childAttrId = pdb.getAttrChild();
    const flagsId = pdb.getAttrNodeFlags();
    const instanceOfId = pdb.getAttrInstanceOf();

    let result = {};
    let instanceOf, parentId, flags;
    const attribsWanted = { ...attribWanted };
    // collect the object's own attributes and remove them from the list of attributes
    // it may inherit
    pdb.enumObjectProperties(dbId, (attrId, valueId) => {
        if (attribsWanted[attrId]) {
            result[attrId] = valueId;
            delete attribsWanted[attrId];
        }
        const same = aliases?.get(attrId);
        same?.forEach(s => { delete attribsWanted[s]; });
        if (attrId === instanceOfId) {
            instanceOf = pdb.getAttrValue(attrId, valueId);
        }
        if (attrId === parentAttrId) {
            parentId = pdb.getAttrValue(attrId, valueId);
            result[parentAttrId] = parentId;
        }
        if (attrId === flagsId) {
            flags = pdb.getAttrValue(attrId, valueId, true);
        }
    });

    if (instanceOf && Object.keys(attribsWanted).length) {
        pdb.getPropertiesSubsetWithInheritance(dbId, attribsWanted, result);
    }

    if (strict) {
        // we're done here...
        return result;
    }

    if (flags === NODE_TYPE_COMPOSITE) {
        // this is a composite node
        result.isComposite = true;
    }

    if (parentId) {
        let parentItem = cache[parentId];
        if (!parentItem) {
            cache[parentId] = {}; // sentinel to avoid endless recursions
            cache[parentId] = parentItem = collectProperties(pdb, parentId, attribWanted, cache, aliases, strict);
        }
        if (!parentItem[parentAttrId]) {
            // do not inherit from root
            return result;
        }

        if (parentItem.isComposite) {
            result.composite = parentId;
        } else {
            for (const key of Object.keys(parentItem)) {
                if (skipKeys.has(key)) continue;

                const k = parseInt(key);
                if (k === parentAttrId || k === childAttrId || k === flagsId || isNaN(k) || !attribsWanted[k]) {
                    continue;
                }

                if (!(key in result)) {
                    result[key] = parentItem[key];
                }
            }
        }
        // TODO: we have no test coverage for "inner" composites,
        // searched for such a model, but didn't find one (a house has only "flat" composites)
        if (parentItem.composite) {
            result.composite = parentItem.composite;
        }
    }
    return result;
}

export function getAttributeDefinitions(pdb, filters) {
    const attrDefs = [];
    filters = filters ? [filters].flat() : undefined; // item/array to array
    pdb.enumAttributes((attrId, attrDef) => {
        const attrDetail = { attrId, ...attrDef };
        const filterResult = filters?.some(filter => filter(attrDetail)) ?? true;
        if (filterResult) {
            attrDefs.push(attrDetail);
        }
    });
    return attrDefs;
}

function toAttributeMatcher(searchValue) {

    const type = typeof searchValue;

    switch (type) {
        case 'number': {
            return (attrDef) => attrDef.attrId === searchValue;
        }
        case 'string': {
            return (attrDef) => attrDef.name === searchValue;
        }
        case 'object': {
            const searchEntries = Object.entries(searchValue);
            return (attrDef) => searchEntries.every(([prop, value]) => attrDef[prop] === value);
        }
        default:
            throw new Error(`Invalid search value (${type}), expected number/string/object`);
    }
}

/**
 * Given a list of attributes and a model, this function extracts all values for the specified attributes and dbIds.
 * up according to the different values of a certain property.
 *
 * Used in non-VDS (legacy) only
 *
 * @param {PropertyDatabase}            pdb
 * @param {object}                      params
 * @param {number[]}                    params.dbIds      - all dbIds to consider. Usually all dbIds that have fragments.
 * @param {Array<string|number|object>} params.attributes - array of strings, attributeIds or attribute specification. Each defines an attribute whose values we use to split into child nodes.
*/
export function getValuesForAttributes(pdb, { dbIds, attributes, strict }) {
    const parentId = pdb.getAttrParent();
    attributes = [attributes].flat(); // item/array to array
    // get the attribute definitions with filter
    const attribMatchers = attributes.map(toAttributeMatcher);
    const attributeDefinitions = getAttributeDefinitions(pdb, attribMatchers);

    const attrWanted = {};
    const attrIdValueSet = {};
    const attrTypes = {};
    const aliases = new Map();

    attributeDefinitions.forEach(attrDef => {
        attrWanted[attrDef.attrId] = true;
        attrIdValueSet[attrDef.attrId] = new Set();
        attrTypes[attrDef.attrId] = attrDef.dataType;

        if (!aliases.get(attrDef.attrId)) {
            const same = attributeDefinitions.filter(a => {
                return a.name.toLowerCase() === attrDef.name.toLowerCase() &&
                    a.category === attrDef.category &&
                    a.dataType === attrDef.dataType &&
                    a.dataTypeContext === attrDef.dataTypeContext;
            }
            ).map(a => a.attrId);

            if (same.length > 1) {
                same.forEach(s => aliases.set(s, same));
            }
        }
    });

    const cache = {};
    const collectValues = (rawItem) => {
        Object.entries(rawItem).forEach(([attrId, valueId]) => {
            // only collect values for attributes other than the parent info
            // `isNaN` is only for `composite` attribute, which is ignored atm
            if (!isNaN(attrId) && parseInt(attrId) !== parentId) {
                // collect the values from the attribute ids
                const v = pdb.getAttrValue(attrId, valueId);

                attrIdValueSet[attrId].add(attrTypes[attrId] === ATTRIBUTE_TYPE_BOOLEAN ? !!v : v);
            }
        });
    };

    const hydrate = (dbId) => {
        const rawItem = collectProperties(pdb, dbId, attrWanted, cache, aliases, strict);
        const compositeId = rawItem.composite;
        if (compositeId) {
            collectValues(cache[compositeId]);
        }
        collectValues(rawItem);
    };

    if (dbIds) {
        dbIds.forEach(hydrate);
    } else {
        pdb.enumObjects(hydrate);
    }

    // unique values
    const result = {};
    attributeDefinitions.forEach(attrDef => {
        attrDef.values = [...attrIdValueSet[attrDef.attrId] ?? []];
        result[attrDef.attrId] = attrDef;
    });

    return result;
}

// Resolve to attributeId if needed.
//  - For a name, find (first) attribute id matching the given name
//  - For an id, return identity
//  - For an attrib specified { name, category }, return the first item matching both.
function toAttributeId(pdb, attribSpec) {

    const type = typeof attribSpec;

    // If it is already an id, just return identity
    const isId = type === 'number';
    if (isId) {
        return attribSpec;
    }

    // A string name corresponds to { name: s }
    const spec = type === 'string' ? { name: attribSpec } : attribSpec;

    let attrId = undefined;

    // Find id of matching attribute
    const cb = (id, def) => {
        const isMatching = Object.keys(spec).every(key => def[key] === spec[key]);
        if (isMatching) {
            console.assert(!attrId, 'Attribute specification is not unique: ', attribSpec);
            attrId = id;
        }
    };
    pdb.enumAttributes(cb);

    console.assert(attrId, 'Attribute not found: ', attribSpec);
    return attrId;
}

// Inserts a path into the given tree structure that matches with the given values
// Example:
//   addToTree({}, {'a', 'b', 'c'}
//   =>  { a: { b: { c: true } } }
function addToTree(tree, path) {

    let node = tree;
    for (let level = 0; level < path.length; level++) {

        const value = path[level];

        // At leaf level, just remember the final value as key
        const leaf = (level === path.length - 1);
        if (leaf) {
            node[value] = true;
            continue;
        }

        // For inner nodes, make sure child node exists
        let child = node[value];
        if (!child) {
            child = node[value] = {};
        }

        // recurse into subtree
        node = child;
    }
}

function buildTree(pdb, attribIds, dbIds) {

    const tree = {};
    const cache = {};
    // create a map that is true for all attributeIds we are interested in
    const attribWanted = {};
    attribIds.forEach(id => attribWanted[id] = true);
    attribWanted[pdb.getAttrParent()] = true;

    for (let i = 0; i < dbIds.length; i++) {
        const dbId = dbIds[i];
        const props = collectProperties(pdb, dbId, attribWanted, cache);

        // add corresponding tree path for this combination of attribute values.
        const treePath = attribIds.map(id => pdb.getValueAt(props[id]));
        addToTree(tree, treePath);
    }

    return tree;
}

/** Given a list of properties and a model, this function extracts a tree structure where each level splits
 * up according to the different values of a certain property.
 * @param {PropertyDb}        pdb
 * @param {object}            params
 * @param {number[]}          params.dbIds           - all dbIds to consider in the tree. Usually all dbIds that have fragments.
 * @param {(string|number)[]} params.levelAttributes - array of strings or attributeIds. Each defines an attribute whose values we use to split into child nodes.
*/
export function createPropertyTree(pdb, params) {

    // find attributeIds for the levelNames
    const attribIds = params.levelAttributes.map(attrib => toAttributeId(pdb, attrib));

    return buildTree(pdb, attribIds, params.dbIds);
}

