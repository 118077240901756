// Like src/index.js but for the main worker
// Currently unused in a node environment (MainWorker-node).

import { default as prefix, adsk_export } from "../../module-prefix";

import * as Viewing from '../../imports/worker/av.js';

export const av = {};
export const avp = {};

// Note: This is only a subset of src/index.js's exports which might cause some
//  undefined exports (e.g. THREE in module-suffix). Add them as needed.
const exports = {
    Autodesk: prefix.Autodesk,
    av,
    avp,
};

adsk_export(exports, Viewing.Private, 'avp');
adsk_export(exports, Viewing, 'av', ['Private']);
export default exports;

import * as moduleSuffix from "../../module-suffix";
moduleSuffix.initializeGlobalNamespaces(exports);
